import { reducePreloads } from '@swe/shared/network/helpers/reduce-preloads';

import { RoutePath } from '@swe/shared/providers/router/constants';
import { normalizeRoute, stringifyRouteUrl } from '@swe/shared/providers/router/utils';
import { getPaginationInfo } from '@swe/shared/utils/pagination';

import { ApplicationPage } from 'app/types';

import { Cont404 } from 'common/containers/404';
import { getSeo } from 'common/use-cases/use-seo';
import { Catalog } from 'domains/catalog/containers/catalog';
import useCatalogQuery, {
  convertToFiltersDescriptor,
  findOptionById,
  getOptionName,
  parseCatalogQuery,
} from 'domains/catalog/use-cases/use-catalog-query';
import useEntryPoint from 'domains/catalog/use-cases/use-entry-point';
import { ProductDetails } from 'domains/product/containers/product-details';
import { GetCarouselListEndpoint } from 'endpoints/product/get-carousel-list';
import GetListEndpoint, { GetListResponse } from 'endpoints/product/get-list';
import GetProductByVariantId from 'endpoints/product/get-product-by-variant-id';
import GetPromoBannerEndpoint from 'endpoints/promotion/get-promotion';
import GetShopInfoEndpoint from 'endpoints/shop/get-shop-info';
import { FilterKey } from 'entities/common/catalog';
import { CompilationType } from 'entities/common/compilation';
import { Discount } from 'entities/discount/discount';
import { ProductCarousel } from 'entities/product/carousel';
import { ProductFilterTag, ProductFilterTagMultiple } from 'entities/product/filter';
import {
  getFirstImage,
  getProductFirstVariant,
  getProductName,
  getProductVariantById,
  Product,
} from 'entities/product/product';
import { ShopInfo } from 'entities/shop/info';

const CatalogPage: ApplicationPage = () => {
  const { catalogQuery } = useCatalogQuery();
  const { pageHeader, catalogFilter, compilationType, isLoading, isCompilationPage } = useEntryPoint();

  if (catalogQuery.page === 'product') {
    return <ProductDetails variantId={catalogQuery.variantId} />;
  }

  if (!pageHeader) {
    return <Cont404 />;
  }

  if (isLoading) return null;

  return (
    <Catalog
      pageHeader={pageHeader}
      compilationType={compilationType}
      compilationTitle={pageHeader.title}
      isCompilationPage={isCompilationPage}
      outerFilters={catalogFilter}
    />
  );
};

CatalogPage.preload = async ({ headers, query, queryClient }, { saleType, platformOs }) => {
  const { pagination, sortingMethod, searchTerm, filters, ...catalogQuery } = parseCatalogQuery(query);
  if (catalogQuery.page === 'main' && catalogQuery.subPath.length > 0) {
    throw new Response('Not Found', { status: 404 });
  }
  let outerFilters: Record<string, unknown> = {};

  switch (catalogQuery.page) {
    case 'product':
      return reducePreloads([
        GetProductByVariantId.preload(
          {
            variantId: catalogQuery.variantId,
            saleType, // required for proper caching
            platformOs,
          },
          { headers },
          queryClient,
        ),
      ]);
    case 'category':
      outerFilters[FilterKey.Category] = [catalogQuery.categoryId];
      break;
    case 'compilation': {
      if (catalogQuery.compilationType === CompilationType.CAROUSEL) {
        const carousels = await GetCarouselListEndpoint.preload({ saleType, platformOs }, { headers }, queryClient);
        const selectedCarousel = carousels?.find(({ id }) => String(id) === catalogQuery.compilationId);
        if (selectedCarousel?.filter) {
          outerFilters = selectedCarousel.filter;
        }
      } else if (catalogQuery.compilationType === CompilationType.DISCOUNT) {
        const promoBanner = await GetPromoBannerEndpoint.preload(
          { saleType, platformOs, promoId: Number(catalogQuery.compilationId) },
          { headers },
          queryClient,
        );
        if (promoBanner?.promo?.filter) {
          outerFilters = promoBanner?.promo?.filter;
        }
      }
    }
  }

  return reducePreloads([
    GetListEndpoint.preload(
      {
        filters: { ...outerFilters, ...filters },
        ...pagination,
        sortingMethodId: sortingMethod,
        searchTerm,
        saleType,
        platformOs,
      },
      { headers },
      queryClient,
    ),
  ]);
};

CatalogPage.getMeta = ({ query, queryClient, intl, platformOs, saleType, pathname, dealerName }) => {
  const storeInfo = queryClient.getQueryData(GetShopInfoEndpoint.key()) as ShopInfo;
  const catalogQuery = parseCatalogQuery(query);
  const { builder, payload } = getSeo(dealerName, storeInfo);
  const { name, cityName, saleTypesFormatted, fulfillmentTypesFormatted } = payload;

  if (catalogQuery.page === 'product') {
    const product = queryClient.getQueryData(
      GetProductByVariantId.key({ variantId: catalogQuery.variantId, platformOs, saleType }),
    ) as Product;
    if (product) {
      const variant = getProductVariantById(product, catalogQuery.variantId) ?? getProductFirstVariant(product);
      const productName = getProductName(product);

      builder.title.add(
        intl.t(
          'pages.productDetails.title',
          {
            ...payload,
            productName: productName ?? '',
            brand: product.brand?.name || '',
            size: variant.name || '',
          },
          'Buy {productName} online - {name} | {city}',
        ),
      );
      builder.shortTitle.add(productName);
      builder.images.add(getFirstImage(product, variant.id));
      builder.type.set('product');

      builder.description.add([
        `Want to buy ${productName} online?`,
        `${name} - your best source for premium ${product?.category?.name} in ${cityName}`,
        saleTypesFormatted,
        ...fulfillmentTypesFormatted,
      ]);
      builder.keywords.add([
        name,
        cityName,
        productName,
        (variant.name && `${productName} ${variant.name}`) || '',
        (product.category?.name && `${productName} ${product.category?.name}`) || '',
        (product.strain?.name && `${productName} ${product.strain?.name}`) || '',
        `buy ${productName}`,
        `${productName}${product.brand?.name ? ` ${product.brand.name}` : ''}`,
      ]);
    }
  } else {
    const productList = queryClient.getQueriesData({ queryKey: GetListEndpoint.key() })[0]?.[1] as GetListResponse;
    if (productList) {
      const filtersDescriptor = convertToFiltersDescriptor(productList.filters) ?? {};
      const allCategories = (filtersDescriptor[FilterKey.Category] as ProductFilterTagMultiple)?.options ?? [];
      const { page, pageSize, total } = productList;
      const commonDescription = [saleTypesFormatted, ...fulfillmentTypesFormatted];
      const commonKeywords = [name, cityName];
      const wantToBuy = (msg = 'Cannabis') => `Want to buy ${msg} online?`;
      const buildExternalLink = (page: number) =>
        stringifyRouteUrl(
          normalizeRoute({ query: { ...query, page: String(page) } }, pathname, storeInfo.url as RoutePath),
        ) as AbsoluteURL;
      const { prevPage, nextPage } = getPaginationInfo({ pageSize, page, total });
      builder.links.add([
        ...(prevPage ? [{ rel: 'prev', href: buildExternalLink(prevPage) }] : []),
        ...(nextPage ? [{ rel: 'next', href: buildExternalLink(nextPage) }] : []),
      ]);
      const strainPrevalence =
        (filtersDescriptor[FilterKey.StrainPrevalence] as ProductFilterTag | undefined)?.options.map(
          ({ name }) => name,
        ) ?? [];

      if (catalogQuery.page === 'category') {
        const categoryName = getOptionName(findOptionById(allCategories, catalogQuery.categoryId));
        builder.title.add(
          intl.t(
            'pages.catalog.category.documentTitle',
            { ...payload, categoryName },
            `Buy {categoryName} online | {storeName} | {cityName}`,
          ),
        );
        builder.description.add([
          wantToBuy(categoryName),
          `${name} - your best source for premium ${categoryName} in ${cityName}`,
          ...commonDescription,
        ]);
        builder.keywords.add([
          ...commonKeywords,
          `Buy ${categoryName} `,
          `Cannabis ${categoryName}`,
          (strainPrevalence.length && `${strainPrevalence.map((strain) => `${strain} ${categoryName}`).join(', ')}`) ||
            '',
        ]);
      } else if (catalogQuery.page === 'compilation') {
        if (catalogQuery.compilationType === CompilationType.CAROUSEL) {
          const carousel = (
            queryClient.getQueriesData({ queryKey: GetCarouselListEndpoint.key() })[0]?.[1] as ProductCarousel[]
          )?.find((carousel) => carousel.id === catalogQuery.compilationId);
          if (carousel) {
            builder.title.add(
              intl.t(
                'pages.catalog.compilation.carousel.documentTitle',
                { ...payload, compilationName: carousel.name },
                `The best selection of cannabis products | {compilationName} | {storeName} | {cityName}`,
              ),
            );
            builder.description.add([
              wantToBuy(),
              `${name} - Your best source for premium products in ${cityName}`,
              ...commonDescription,
            ]);
            builder.keywords.add([...commonKeywords, carousel.name]);
          }
        } else if (catalogQuery.compilationType === CompilationType.DISCOUNT) {
          const discount = queryClient.getQueriesData({ queryKey: GetPromoBannerEndpoint.key() })[0]?.[1] as {
            promo?: Discount;
          };
          if (discount?.promo) {
            builder.title.add(
              intl.t(
                'pages.catalog.compilation.discount.documentTitle',
                { ...payload, discountName: discount.promo.name },
                `Discounts and Best Deals for cannabis products online | {discountName} | {storeName} | {cityName}`,
              ),
            );
            builder.description.add([
              'Want to find the best deals and discounts for cannabis products online?',
              `${name} - your best source for premium products in ${cityName}`,
              ...commonDescription,
            ]);
            builder.keywords.add([...commonKeywords, discount.promo.name]);
          }
        }
      } else {
        builder.title.add(
          intl.t('pages.catalog.documentTitle', payload, `Buy Cannabis online | {storeName} | {cityName}`),
        );
        builder.description.add([
          wantToBuy(),
          `Welcome to ${name} in ${cityName}`,
          saleTypesFormatted,
          ...fulfillmentTypesFormatted,
        ]);

        const categoriesIDs = catalogQuery.filters[FilterKey.Category] ?? [];
        const allCategoriesMap = Object.fromEntries(allCategories.map((cat) => [cat.id, cat.name]));
        const categoriesSeoNames = categoriesIDs.map((category: string) => allCategoriesMap[category]);
        builder.keywords.add([
          ...commonKeywords,
          (strainPrevalence.length && `${strainPrevalence.map((strain) => `cannabis ${strain}`).join(', ')}`) || '',
          `cannabis ${categoriesSeoNames.length ? categoriesSeoNames.join(', ') : 'all categories'}`,
        ]);
      }
    }
  }

  return builder.toProps();
};

CatalogPage.getMainLayoutProps = () => ({ centered: true });

export default CatalogPage;
